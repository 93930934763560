<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="css">
@import './assets/style/main.css';
@import './assets/style/tailwind_auth.css';
@import './assets/style/tailwind_teams.css';

</style>

<script>

export default {
  // mounted() {
  //       const plugin = document.createElement("script");
  //       plugin.setAttribute("src", "/script.js"); // Note the leading slash
  //       plugin.async = true;
  //       document.head.appendChild(plugin);
  //   }
}
</script>
