import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './filters/index'
import titleMixin from './mixin/titleMixin'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const app = createApp(App)
app.mixin(titleMixin)

app.use(store).use(router).use(ToastPlugin).mount('#app')

app.config.globalProperties.$filters = filters

