import baseAPI from '@/api/base';
import { EditTeamGroupDto, NewTeamGroupDto } from '@/dtos/team/group.dto';
import { CREATE_TEAM_GROUP_URL, DELETE_TEAM_GROUP_URL, EDIT_TEAM_GROUP_URL, GET_TEAM_GROUP_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Group {
    static getTeamGroup = async(teamId: string | null) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(`${GET_TEAM_GROUP_URL}/${teamId}`, {
                    ...(teamId != null ? { params: { teamId } } : {})
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
    
    static createTeamGroup = async(newTeamGroupDto: NewTeamGroupDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(CREATE_TEAM_GROUP_URL, {
                    ...newTeamGroupDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editTeamGroup = async(editTeamGroupDto: EditTeamGroupDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(`${EDIT_TEAM_GROUP_URL}`, {
                    ...editTeamGroupDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static addMemberToGroup = async(editTeamGroupDto: EditTeamGroupDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(`${EDIT_TEAM_GROUP_URL}`, {
                    ...editTeamGroupDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteTeamGroup = async(groupId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_TEAM_GROUP_URL}/${groupId}`, {
                    data: { groupId }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Group