import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import { DASHBOARD_ROUTE, LOGIN_OTP_ROUTE, LOGIN_ROUTE, PASSWORD_RESET_SUCCESSFUL_ROUTE, REQUEST_RESET_PASSWORD_ROUTE, RESET_PASSWORD_ROUTE, SIGNUP_OTP_ROUTE, SIGNUP_ROUTE, SIGNUP_SUCCESS_ROUTE, TEAM_GROUPS_ROUTE, TEAM_MEMBERS_ROUTE, TEAM_ROLES_ROUTE, VERIFY_PASSWORD_RESET_OTP_ROUTE } from './routes'
import { ifAuthDeny } from './guards/auth'
import { ifNotAuthDeny } from './guards/not_auth'
import { resetLoaders } from '@/utils/loaders'
import store from '@/store'
import { CLEAR_ERRORMSG } from '@/store/action_types'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: DASHBOARD_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: Dashboard
  },
  {
    path: '/login',
    name: LOGIN_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/login/Login.vue')
  },
  {
    path: '/login-otp',
    name: LOGIN_OTP_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/login/Login_OTP.vue')
  },
  {
    path: '/signup',
    name: SIGNUP_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/signup/Signup.vue')
  },
  {
    path: '/signup-otp',
    name: SIGNUP_OTP_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/signup/Signup_OTP.vue')
  },
  {
    path: '/signup-success',
    name: SIGNUP_SUCCESS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('../views/auth/signup/Signup_Success.vue')
  },
  {
    path: '/request-reset-password',
    name: REQUEST_RESET_PASSWORD_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/Request_Password_Reset.vue')
  },
  {
    path: '/verify-reset-password-otp',
    name: VERIFY_PASSWORD_RESET_OTP_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/Verify_Reset_Password_OTP.vue')
  },
  {
    path: '/reset-password',
    name: RESET_PASSWORD_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/Reset_Password.vue')
  },
  {
    path: '/reset-password-successful',
    name: PASSWORD_RESET_SUCCESSFUL_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/Password_Reset_Success.vue')
  },
  {
    path: '/team/groups/:teamId',
    name: TEAM_GROUPS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('../views/teams/Team_Groups.vue')
  },
  {
    path: '/team/roles/:teamId',
    name: TEAM_ROLES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('../views/teams/Team_Roles.vue')
  },
  {
    path: '/team/members/:teamId',
    name: TEAM_MEMBERS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('../views/teams/Team_Members.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  resetLoaders()
  store.dispatch(CLEAR_ERRORMSG)

  next();
});


export default router
