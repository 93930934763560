import axios, { AxiosResponse } from 'axios'
import { SET_TEAM_GROUP, SET_ACCESS_TOKEN, SET_REFRESH_TOKEN, SET_ERRORMSG, SET_TEAM_GROUPS } from '@/store/mutation_types'
import {
    GET_TEAM_GROUP,
    GET_TEAM_GROUPS,
    CREATE_TEAM_GROUP,
    EDIT_TEAM_GROUP,
    DELETE_TEAM_GROUP,
    CLEAR_TEAM_GROUP_ISLOADING,
    ADD_MEMBER_TO_GROUP
} from '@/store/action_types'
import Group from '@/services/team/group'
import { StateObject } from '@/dtos/state'
import { LoginCredentals, RegistrationCredentals, ResetPassword, VerifyOTP, VerifyPasswordResetOTP } from '@/dtos/auth.dto'
import { Module } from 'vuex'
import { AddMemberToGroupDto, EditTeamGroupDto, NewTeamGroupDto } from '@/dtos/team/group.dto'

const group: Module<any, {}> = {
    state: {
        group: {
            data: null,
            isLoading: false
        },
        groups: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        group: (state: { group: any }) => state.group,
        groups: (state: { groups: any }) => state.groups
    },
    mutations: {
        [SET_TEAM_GROUP]: (state: { group: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.group.data = payload.data : null, state.group.isLoading = payload.isLoading },
        [SET_TEAM_GROUPS]: (state: { groups: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.groups.data = payload.data : null, state.groups.isLoading = payload.isLoading }
    },
    actions: {
        [GET_TEAM_GROUP]: async({ commit }: any, payload: string) => {
            commit(SET_TEAM_GROUP, { data: null, isLoading: true })

            let result: AxiosResponse = await Group.getTeamGroup(payload)
          
            if(result) {
                if (result.data.success) {
                    console.log(result.data)
                    commit(SET_TEAM_GROUP, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_GROUP, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_GROUP, { isLoading: false })
                return false;
            }
        },
        [GET_TEAM_GROUPS]: async({ commit }: any, payload: string) => {

            commit(SET_TEAM_GROUPS, { data: null, isLoading: true })

            let result: AxiosResponse = await Group.getTeamGroup(payload)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_GROUPS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_GROUPS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_GROUPS, { isLoading: false })
                return false;
            }
        },
        [CREATE_TEAM_GROUP]: async({ commit }: any, payload: any) => {

            commit(SET_TEAM_GROUP, { data: null, isLoading: true })

            let result: AxiosResponse = await Group.createTeamGroup(payload as NewTeamGroupDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_GROUP, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_GROUP, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_GROUP, { isLoading: false })
                return false;
            }
        },
        [EDIT_TEAM_GROUP]: async({ commit }: any, payload: any) => {

            commit(SET_TEAM_GROUP, { data: null, isLoading: true })

            let result: AxiosResponse = await Group.editTeamGroup(payload as EditTeamGroupDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_GROUP, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_GROUP, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_GROUP, { data: null, isLoading: false })
                return false;
            }
        },
        [ADD_MEMBER_TO_GROUP]: async({ commit }: any, payload: any) => {

            commit(SET_TEAM_GROUP, { data: null, isLoading: true })

            let result: AxiosResponse = await Group.addMemberToGroup(payload as AddMemberToGroupDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_GROUP, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_GROUP, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_GROUP, { data: null, isLoading: false })
                return false;
            }
        },
        [DELETE_TEAM_GROUP]: async({ commit }: any, payload: string) => {
            commit(SET_TEAM_GROUP, { data: null, isLoading: true })

            let result: AxiosResponse = await Group.deleteTeamGroup(payload)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_GROUP, { isLoading: false })
                    return true;
                }
                commit(SET_TEAM_GROUP, { isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_GROUP, { isLoading: false })
                return false;
            }
        },
        [CLEAR_TEAM_GROUP_ISLOADING]: ({ commit }) => {
            commit(SET_TEAM_GROUP, { isLoading: false })
            commit(SET_TEAM_GROUPS, { isLoading: false })
        }
    }
}

export default group