// account
export const SET_ACCOUNT = 'setAccount';
export const SET_ACCESS_TOKEN = 'setAccessToken';
export const SET_REFRESH_TOKEN = 'setRefreshToken';

// team > group
export const SET_TEAM_GROUP = 'setTeamGroup';
export const SET_TEAM_GROUPS = 'setTeamGroups';

// team > role
export const SET_TEAM_ROLE = 'setTeamRole';
export const SET_TEAM_ROLES = 'setTeamRoles';

// team > member
export const SET_TEAM_MEMBER = 'setTeamMember';
export const SET_TEAM_MEMBERS = 'setTeamMembers';

// error
export const SET_ERRORMSG = 'setErrorMsg';