import baseAPI from '@/api/base';
import { LoginCredentals, ResetPassword, VerifyOTP, VerifyPasswordResetOTP } from '@/dtos/auth.dto';
import { LOGIN_URL, LOGIN_VERIFY_OTP_URL, LOGOUT_URL, REQUEST_PASSWORD_RESET_URL, RESEND_OTP_URL, RESET_PASSWORD_URL, SIGNUP_URL, SIGNUP_VERIFY_OTP_URL, VERIFY_RESET_PASSWORD_OTP_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Auth {
    static login = async(loginCredentals: LoginCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(LOGIN_URL, {
                    ...loginCredentals,

                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
    
    static signup = async(formData: FormData) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(SIGNUP_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static verifyOTP = async(verifyOTP: VerifyOTP) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(`${SIGNUP_VERIFY_OTP_URL}/${verifyOTP.email}`, {
                    ...verifyOTP
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static resendOTP = async(email: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(`${RESEND_OTP_URL}/${email}`, {
                    email
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static requestPasswordReset = async(email: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(`${REQUEST_PASSWORD_RESET_URL}/${email}`, {
                    email
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static verifyResetPasswordOTP = async(verifyPasswordResetOTP: VerifyPasswordResetOTP) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(`${VERIFY_RESET_PASSWORD_OTP_URL}/${verifyPasswordResetOTP.email}`, {
                    ...verifyPasswordResetOTP
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static resetPassword = async(resetPassword: ResetPassword) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(`${RESET_PASSWORD_URL}/${resetPassword.email}`, {
                    ...resetPassword
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static logout = async() : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(LOGOUT_URL)
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Auth