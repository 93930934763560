import axios, { AxiosResponse } from 'axios'
import { SET_TEAM_MEMBER, SET_TEAM_MEMBERS, SET_ERRORMSG } from '@/store/mutation_types'
import {
    GET_TEAM_MEMBER,
    GET_TEAM_MEMBERS,
    CREATE_TEAM_MEMBER,
    EDIT_TEAM_MEMBER,
    DELETE_TEAM_MEMBER,
    CLEAR_TEAM_MEMBER_ISLOADING
} from '@/store/action_types'
import Member from '@/services/team/member'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import { EditTeamMemberDto, NewTeamMemberDto, RemoveTeamMemberDto } from '@/dtos/team/member.dto'

const member: Module<any, {}> = {
    state: {
        member: {
            data: null,
            isLoading: false
        },
        members: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        member: (state: { member: any }) => state.member,
        members: (state: { members: any }) => state.members
    },
    mutations: {
        [SET_TEAM_MEMBER]: (state: { member: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.member.data = payload.data : null, state.member.isLoading = payload.isLoading },
        [SET_TEAM_MEMBERS]: (state: { members: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.members.data = payload.data : null, state.members.isLoading = payload.isLoading }
    },
    actions: {
        [GET_TEAM_MEMBER]: async({ commit }: any, payload: string) => {
            commit(SET_TEAM_MEMBER, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.getTeamMember(payload)
          
            if(result) {
                if (result.data.success) {
                    console.log(result.data)
                    commit(SET_TEAM_MEMBER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_MEMBER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_MEMBER, { isLoading: false })
                return false;
            }
        },
        [GET_TEAM_MEMBERS]: async({ commit }: any, payload: string) => {

            commit(SET_TEAM_MEMBERS, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.getTeamMember(payload)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_MEMBERS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_MEMBERS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_MEMBERS, { isLoading: false })
                return false;
            }
        },
        [CREATE_TEAM_MEMBER]: async({ commit }: any, payload: any) => {

            commit(SET_TEAM_MEMBER, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.addTeamMember(payload as NewTeamMemberDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_MEMBER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_MEMBER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_MEMBER, { isLoading: false })
                return false;
            }
        },
        [EDIT_TEAM_MEMBER]: async({ commit }: any, payload: any) => {

            commit(SET_TEAM_MEMBER, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.editTeamMember(payload as EditTeamMemberDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_MEMBER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_MEMBER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_MEMBER, { data: null, isLoading: false })
                return false;
            }
        },
        [DELETE_TEAM_MEMBER]: async({ commit }: any, payload: any) => {
            commit(SET_TEAM_MEMBER, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.deleteTeamMember(payload as RemoveTeamMemberDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_MEMBER, { isLoading: false })
                    return true;
                }
                commit(SET_TEAM_MEMBER, { isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_MEMBER, { isLoading: false })
                return false;
            }
        },
        [CLEAR_TEAM_MEMBER_ISLOADING]: ({ commit }) => {
            commit(SET_TEAM_MEMBER, { isLoading: false })
            commit(SET_TEAM_MEMBERS, { isLoading: false })
        }
    }
}

export default member