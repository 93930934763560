import baseAPI from '@/api/base';
import { LoginCredentals, ResetPassword, VerifyOTP, VerifyPasswordResetOTP } from '@/dtos/auth.dto';
import { EditTeamMemberDto, NewTeamMemberDto, RemoveTeamMemberDto } from '@/dtos/team/member.dto';
import { CREATE_TEAM_MEMBER_URL, DELETE_TEAM_GROUP_URL, DELETE_TEAM_MEMBER_URL, EDIT_TEAM_GROUP_URL, GET_TEAM_MEMBER_URL } from '@/utils/urls';
import axios, { AxiosResponse } from 'axios';

class Member {
    static getTeamMember = async(teamId: string | null) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(`${GET_TEAM_MEMBER_URL}/${teamId}`, {
                    ...(teamId != null ? { params: { teamId } } : {})
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
    
    static addTeamMember = async(newTeamMemberDto: NewTeamMemberDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(CREATE_TEAM_MEMBER_URL, {
                    ...newTeamMemberDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editTeamMember = async(editTeamMemberDto: EditTeamMemberDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(`${EDIT_TEAM_GROUP_URL}`, {
                    ...editTeamMemberDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteTeamMember = async(removeTeamMemberDto: RemoveTeamMemberDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_TEAM_MEMBER_URL}/${removeTeamMemberDto.teamId}`, {
                    data: removeTeamMemberDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Member