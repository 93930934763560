import store from '@/store';
import router from '..'
import { LOGIN_ROUTE } from '@/router/routes'
// import { resetLoaders } from '@/utils/reset_loaders';
import { CLEAR_ERRORMSG } from '@/store/action_types';
import { resetLoaders } from '@/utils/loaders';
import { RouteLocationNormalized } from 'vue-router';

export const ifNotAuthDeny = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    // clear error messages
    store.dispatch(CLEAR_ERRORMSG)

    // clear isLoading states
    resetLoaders()

    if (store.getters.accessToken == null || store.getters.account.data == null) {
        router.push({ name: LOGIN_ROUTE })
        return
    }

    // if user is logged in make a call to update user account on each authorized route visit
    // store.dispatch(UPDATE_AUTH_ACCOUNT)
    next();
}