import axios, { AxiosResponse } from 'axios'
import { SET_TEAM_ROLE, SET_TEAM_ROLES, SET_ERRORMSG } from '@/store/mutation_types'
import {
    GET_TEAM_ROLE,
    GET_TEAM_ROLES,
    CREATE_TEAM_ROLE,
    EDIT_TEAM_ROLE,
    DELETE_TEAM_ROLE,
    CLEAR_TEAM_ROLE_ISLOADING,
    ADD_MEMBER_TO_ROLE,
} from '@/store/action_types'
import Role from '@/services/team/role'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import { AddMemberToRoleDto, EditTeamRoleDto, NewTeamRoleDto } from '@/dtos/team/role.dto'

const role: Module<any, {}> = {
    state: {
        role: {
            data: null,
            isLoading: false
        },
        roles: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        role: (state: { role: any }) => state.role,
        roles: (state: { roles: any }) => state.roles
    },
    mutations: {
        [SET_TEAM_ROLE]: (state: { role: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.role.data = payload.data : null, state.role.isLoading = payload.isLoading },
        [SET_TEAM_ROLES]: (state: { roles: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.roles.data = payload.data : null, state.roles.isLoading = payload.isLoading }
    },
    actions: {
        [GET_TEAM_ROLE]: async({ commit }: any, payload: string) => {
            commit(SET_TEAM_ROLE, { data: null, isLoading: true })

            let result: AxiosResponse = await Role.getTeamRole(payload)
          
            if(result) {
                if (result.data.success) {
                    console.log(result.data)
                    commit(SET_TEAM_ROLE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_ROLE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_ROLE, { isLoading: false })
                return false;
            }
        },
        [GET_TEAM_ROLES]: async({ commit }: any, payload: string) => {

            commit(SET_TEAM_ROLES, { data: null, isLoading: true })

            let result: AxiosResponse = await Role.getTeamRole(payload)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_ROLES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_ROLES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_ROLES, { isLoading: false })
                return false;
            }
        },
        [CREATE_TEAM_ROLE]: async({ commit }: any, payload: any) => {

            commit(SET_TEAM_ROLE, { data: null, isLoading: true })

            let result: AxiosResponse = await Role.createTeamRole(payload as NewTeamRoleDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_ROLE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_ROLE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_ROLE, { isLoading: false })
                return false;
            }
        },
        [EDIT_TEAM_ROLE]: async({ commit }: any, payload: any) => {

            commit(SET_TEAM_ROLE, { data: null, isLoading: true })

            let result: AxiosResponse = await Role.editTeamRole(payload as EditTeamRoleDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_ROLE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_ROLE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_ROLE, { data: null, isLoading: false })
                return false;
            }
        },
        [ADD_MEMBER_TO_ROLE]: async({ commit }: any, payload: any) => {

            commit(SET_TEAM_ROLE, { data: null, isLoading: true })

            let result: AxiosResponse = await Role.addMemberToRole(payload as AddMemberToRoleDto)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_ROLE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEAM_ROLE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_ROLE, { data: null, isLoading: false })
                return false;
            }
        },
        [DELETE_TEAM_ROLE]: async({ commit }: any, payload: string) => {
            commit(SET_TEAM_ROLE, { data: null, isLoading: true })

            let result: AxiosResponse = await Role.deleteTeamRole(payload)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_TEAM_ROLE, { isLoading: false })
                    return true;
                }
                commit(SET_TEAM_ROLE, { isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_TEAM_ROLE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_TEAM_ROLE_ISLOADING]: ({ commit }) => {
            commit(SET_TEAM_ROLE, { isLoading: false })
            commit(SET_TEAM_ROLES, { isLoading: false })
        }
    }
}

export default role