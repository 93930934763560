import { createStore } from 'vuex'
import createVuexPersistedState from 'vuex-persistedstate';
import { LOCAL_STORAGE_KEY } from "@/utils/const";
import account from './modules/account'
import error from './modules/error';
import group from './modules/team/group';
import member from './modules/team/member';
import role from './modules/team/role';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    error,
    group,
    member,
    role
  },
  plugins: [
    createVuexPersistedState({
        key: LOCAL_STORAGE_KEY,
        storage: window.localStorage
    })
  ]
})
