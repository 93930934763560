import { DATETIME_OPTIONS, DATE_OPTIONS } from '@/utils/const';

const filters = {
    firstLetter(value: string) {
        return value != null ? value[0] : ''
    },

    capitalize(value: string) {
        return value != null ? value.charAt(0).toUpperCase() + value.slice(1) : '';
    },

    uppercase(value: string) {
        return value != null ? value.toString().toUpperCase() : ''
    },

    getDateOnly(value: string) {
        return value != null ? new Date(value).toLocaleDateString("en-US", DATE_OPTIONS) : '-'
    },

    getDateAndTime(value: string) {
        return value != null ? new Date(value).toLocaleDateString("en-US", DATETIME_OPTIONS) : '-'
    },
    formatPrice(value: number, currencyISO3: string) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyISO3,
            minimumFractionDigits: 0
        });
        return value != null ? formatter.format(value) : '-'
    },
}

export default filters