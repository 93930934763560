// account
export const LOGIN = 'login'
export const LOGIN_OTP_VERIFY = 'loginOtpVerify'
export const SIGNUP = 'signup'
export const VERIFY_OTP = 'signupOtpVerify'
export const RESEND_OTP = 'resendOtp'
export const REQUEST_PASSWORD_RESET = 'requestPasswordReset'
export const VERIFY_PASSWORD_RESET_OTP = 'verifyPasswordResetOtp'
export const RESET_PASSWORD = 'resetPassword'
export const LOGOUT = 'logout'
export const CLEAR_ACCOUNT_ISLOADING = 'clearAccountIsLoading'

// team > group 
export const GET_TEAM_GROUP = 'getTeamGroup'
export const GET_TEAM_GROUPS = 'getTeamGroups'
export const CREATE_TEAM_GROUP = 'createTeamGroup'
export const EDIT_TEAM_GROUP = 'editTeamGroup'
export const ADD_MEMBER_TO_GROUP = 'addMemberToGroup'
export const DELETE_TEAM_GROUP = 'deleteTeamGroups'
export const CLEAR_TEAM_GROUP_ISLOADING = 'clearTeamGroupIsLoading'

// team > role 
export const GET_TEAM_ROLE = 'getTeamRole'
export const GET_TEAM_ROLES = 'getTeamRoles'
export const CREATE_TEAM_ROLE = 'createTeamRole'
export const EDIT_TEAM_ROLE = 'editTeamRole'
export const ADD_MEMBER_TO_ROLE = 'addMemberToRole'
export const DELETE_TEAM_ROLE = 'deleteTeamRoles'
export const CLEAR_TEAM_ROLE_ISLOADING = 'clearTeamRoleIsLoading'

// team > member 
export const GET_TEAM_MEMBER = 'getTeamMember'
export const GET_TEAM_MEMBERS = 'getTeamMembers'
export const CREATE_TEAM_MEMBER = 'createTeamMember'
export const EDIT_TEAM_MEMBER = 'editTeamMember'
export const DELETE_TEAM_MEMBER = 'deleteTeamMembers'
export const CLEAR_TEAM_MEMBER_ISLOADING = 'clearTeamMemberIsLoading'

// error 
export const CLEAR_ERRORMSG = 'clearErrorMessage'
export const UPDATE_ERRORMSG = 'updateErrorMessage'