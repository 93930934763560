<template>
    <div class="bg-dpdarkblue-500 flex flex-col hidden items-center overflow-y-auto pb-3 self-stretch w-60 md:flex" id="sidebar">
        <div class="flex items-center justify-between p-4 min-h-[60px] w-full" id="head">
            <div class="flex justify-start" id="logo">
                <svg width="110" height="40" viewBox="0 0 175 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6">
                        <path d="M174.421 17.9432C174.352 16.1186 173.893 14.7327 173.067 13.8319C172.172 12.8388 170.772 12.3306 168.89 12.3306C167.765 12.3306 166.687 12.6078 165.7 13.1621C164.851 13.6241 164.162 14.1784 163.588 14.8251V14.1553C163.588 13.37 162.969 12.7464 162.188 12.7464H159.411C158.631 12.7464 158.011 13.37 158.011 14.1553V31.0392C158.011 31.8245 158.631 32.4481 159.411 32.4481H162.188C162.969 32.4481 163.588 31.8245 163.588 31.0392V19.1443C163.887 18.5668 164.3 18.0587 164.805 17.6199C165.31 17.181 165.906 16.95 166.595 16.95C167.398 16.95 167.972 17.1348 168.316 17.5275C168.661 17.9201 168.844 18.4975 168.844 19.2828V31.0392C168.844 31.8245 169.464 32.4481 170.244 32.4481H173.021C173.802 32.4481 174.421 31.8245 174.421 31.0392V18.0356C174.421 18.0125 174.421 17.9894 174.421 17.9432Z" fill="currentColor" class="dark:text-dpgrey-50 text-white"/>
                        <path d="M153.237 32.4421H151.264C150.277 32.4421 149.474 31.6337 149.474 30.6405V14.3109C149.474 13.3177 150.277 12.5093 151.264 12.5093H153.237C154.224 12.5093 155.028 13.3177 155.028 14.3109V30.6405C155.028 31.6337 154.224 32.4421 153.237 32.4421Z" fill="currentColor" class="dark:text-dpgrey-50 text-white"/>
                        <path d="M153.237 10.6404H151.264C150.277 10.6404 149.474 9.83205 149.474 8.83887V7.12969C149.474 6.13652 150.277 5.32812 151.264 5.32812H153.237C154.224 5.32812 155.028 6.13652 155.028 7.12969V8.83887C155.028 9.83205 154.224 10.6404 153.237 10.6404Z" fill="currentColor" class="dark:text-dpgrey-50 text-white"/>
                        <path d="M145.526 16.5805C144.906 15.033 144.08 13.8319 143.001 13.0235C141.923 12.2151 140.706 11.8225 139.329 11.8225C138.182 11.8225 137.172 12.0996 136.254 12.6309C135.519 13.0697 134.946 13.601 134.51 14.2246V14.0398C134.51 13.0466 133.706 12.2382 132.719 12.2382H130.746C129.759 12.2382 128.955 13.0466 128.955 14.0398V38.1993C128.955 39.1925 129.759 40.0009 130.746 40.0009H132.719C133.706 40.0009 134.51 39.1925 134.51 38.1993V31.2702L134.533 31.2933C135.634 32.0786 136.919 32.4712 138.411 32.4712C140.959 32.4712 142.933 31.5473 144.333 29.6996C145.733 27.8518 146.444 25.3342 146.444 22.17C146.444 19.9988 146.146 18.128 145.526 16.5805ZM137.195 27.9904C136.277 27.9904 135.382 27.7594 134.533 27.2513V18.7054C135.405 17.2041 136.437 16.4419 137.654 16.4419C139.719 16.4419 140.729 18.3358 140.729 22.1469C140.729 26.0502 139.559 27.9904 137.195 27.9904Z" fill="currentColor" class="dark:text-dpgrey-50 text-white"/>
                        <path d="M125.903 21.8215C125.903 18.9343 125.237 16.6015 123.929 14.7769C122.621 12.9522 120.555 12.0514 117.778 12.0514C114.91 12.0514 112.683 12.8829 111.054 14.5459C109.424 16.2089 108.621 18.8189 108.621 22.3758C108.621 25.5401 109.424 27.9884 111.054 29.7899C112.683 31.5915 115.139 32.4692 118.444 32.4692C119.247 32.4692 120.188 32.3768 121.267 32.1689C122.346 31.9611 123.264 31.6608 124.044 31.2451C124.824 30.8293 125.215 30.3212 125.215 29.7438C125.215 29.4897 125.1 29.1432 124.893 28.6582C124.687 28.1963 124.411 27.7805 124.113 27.411C123.792 27.0414 123.516 26.8566 123.264 26.8566C123.103 26.8566 122.828 26.949 122.437 27.1569C121.818 27.411 121.198 27.6188 120.624 27.7805C120.051 27.9422 119.408 28.0346 118.696 28.0346C117.388 28.0346 116.378 27.7343 115.644 27.1338C114.91 26.5333 114.496 25.4939 114.359 24.0619H124.503C125.283 24.0619 125.903 23.4383 125.903 22.653V21.9832C125.903 21.937 125.903 21.9139 125.903 21.8677C125.903 21.8446 125.903 21.8215 125.903 21.8215ZM117.778 16.463C118.673 16.463 119.362 16.8094 119.821 17.5023C120.28 18.1952 120.51 19.1191 120.51 20.274H114.405C114.795 17.7564 115.919 16.463 117.778 16.463Z" fill="currentColor" class="dark:text-dpgrey-50 text-white"/>
                        <path d="M105.614 17.9432C105.546 16.1186 105.087 14.7327 104.26 13.8319C103.365 12.8388 101.965 12.3306 100.083 12.3306C98.9587 12.3306 97.88 12.6078 96.8931 13.1621C96.0439 13.6241 95.3554 14.1784 94.7816 14.8251V14.1553C94.7816 13.37 94.162 12.7464 93.3816 12.7464H90.6046C89.8243 12.7464 89.2046 13.37 89.2046 14.1553V31.0392C89.2046 31.8245 89.8243 32.4481 90.6046 32.4481H93.3816C94.162 32.4481 94.7816 31.8245 94.7816 31.0392V19.1443C95.08 18.5668 95.4931 18.0587 95.998 17.6199C96.503 17.181 97.0997 16.95 97.7882 16.95C98.5915 16.95 99.1653 17.1348 99.5095 17.5275C99.8538 17.9201 100.037 18.4975 100.037 19.2828V31.0392C100.037 31.8245 100.657 32.4481 101.437 32.4481H104.214C104.995 32.4481 105.614 31.8245 105.614 31.0392V18.0356C105.637 18.0125 105.637 17.9894 105.614 17.9432Z" fill="currentColor" class="dark:text-dpgrey-50 text-white"/>
                        <path d="M70.3159 29.8138C68.7552 28.0584 67.9749 25.5178 67.9749 22.2149C67.9749 18.912 68.7552 16.3944 70.3159 14.6391C71.8765 12.9068 74.1487 12.0291 77.1323 12.0291C80.1159 12.0291 82.388 12.9068 83.9257 14.6391C85.4635 16.3713 86.2438 18.912 86.2438 22.2149C86.2438 25.5178 85.4635 28.0584 83.9257 29.8138C82.388 31.5692 80.1159 32.4469 77.1323 32.4469C74.1487 32.4469 71.8765 31.5692 70.3159 29.8138ZM79.7487 26.5571C80.3225 25.5871 80.5979 24.1319 80.5979 22.2149C80.5979 20.2978 80.3225 18.8658 79.7487 17.8957C79.1749 16.9257 78.3028 16.4637 77.1323 16.4637C75.9618 16.4637 75.0667 16.9488 74.4929 17.8957C73.9192 18.8658 73.6208 20.2978 73.6208 22.2149C73.6208 26.0721 74.7913 28.0122 77.1093 28.0122C78.3028 28.0122 79.1749 27.5272 79.7487 26.5571Z" fill="currentColor" class="dark:text-dpgrey-50 text-white"/>
                        <path d="M61.893 9.37191C59.8274 7.0853 56.8438 5.95355 52.9651 5.95355H44.7945C44.0142 5.95355 43.3945 6.57717 43.3945 7.36247V31.06C43.3945 31.8453 44.0142 32.4689 44.7945 32.4689H52.9651C56.8438 32.4689 59.8274 31.3372 61.893 29.0506C63.9585 26.764 64.9913 23.4842 64.9913 19.2343C64.9913 14.9383 63.9585 11.6585 61.893 9.37191ZM57.5093 25.3781C56.4766 26.7871 54.9618 27.5031 52.9651 27.5031H49.247V10.8963H52.9651C54.9618 10.8963 56.4995 11.6123 57.5093 13.0212C58.5421 14.4302 59.0471 16.4858 59.0471 19.1881C59.0471 21.8905 58.5421 23.9461 57.5093 25.3781Z" fill="currentColor" class="dark:text-dpgrey-50 text-white"/>
                        <path d="M16.1058 32.0293L12.5255 35.6325C11.9517 36.2099 11.0337 36.2099 10.4828 35.6325L6.9025 32.0293C6.32873 31.4519 6.32873 30.528 6.9025 29.9737L10.4828 26.3706C11.0566 25.7931 11.9746 25.7931 12.5255 26.3706L16.1058 29.9737C16.6566 30.528 16.6566 31.4519 16.1058 32.0293Z" fill="#76B243"/>
                        <path d="M9.6566 25.5371L6.07625 29.1403C5.50248 29.7177 4.58444 29.7177 4.03362 29.1403L0.43033 25.5371C-0.143443 24.9597 -0.143443 24.0358 0.43033 23.4815L4.01067 19.8784C4.58444 19.301 5.50248 19.301 6.0533 19.8784L9.6566 23.4815C10.2304 24.0358 10.2304 24.9597 9.6566 25.5371Z" fill="#76B243"/>
                        <path d="M22.532 25.5371L18.9516 29.1403C18.3778 29.7177 17.4598 29.7177 16.909 29.1403L13.3286 25.5371C12.7549 24.9597 12.7549 24.0358 13.3286 23.4815L16.909 19.8784C17.4828 19.301 18.4008 19.301 18.9516 19.8784L22.532 23.4815C23.1057 24.0358 23.1057 24.9597 22.532 25.5371Z" fill="#76B243"/>
                        <path d="M28.9813 19.0489L25.401 22.652C24.8272 23.2294 23.9091 23.2294 23.3583 22.652L19.778 19.0489C19.2042 18.4714 19.2042 17.5476 19.778 16.9932L23.3583 13.3901C23.9321 12.8127 24.8501 12.8127 25.401 13.3901L28.9813 16.9932C29.5551 17.5707 29.5551 18.4945 28.9813 19.0489Z" fill="#76B243"/>
                        <path d="M16.1058 19.0489L12.5255 22.652C11.9517 23.2294 11.0337 23.2294 10.4828 22.652L6.87955 19.0489C6.30578 18.4714 6.30578 17.5476 6.87955 16.9932L10.4599 13.3901C11.0337 12.8127 11.9517 12.8127 12.5025 13.3901L16.0829 16.9932C16.6566 17.5707 16.6566 18.4945 16.1058 19.0489Z" fill="#91C959"/>
                        <path d="M9.6566 12.5801L6.07625 16.1833C5.50248 16.7607 4.58444 16.7607 4.03362 16.1833L0.43033 12.5801C-0.143443 12.0027 -0.143443 11.0788 0.43033 10.5245L4.01067 6.92135C4.58444 6.34392 5.50248 6.34392 6.0533 6.92135L9.6566 10.5014C10.2304 11.0788 10.2304 12.0027 9.6566 12.5801Z" fill="#91C959"/>
                        <path d="M22.532 12.5801L18.9516 16.1833C18.3778 16.7607 17.4598 16.7607 16.909 16.1833L13.3286 12.5801C12.7549 12.0027 12.7549 11.0788 13.3286 10.5245L16.909 6.92135C17.4828 6.34392 18.4008 6.34392 18.9516 6.92135L22.532 10.5245C23.1057 11.0788 23.1057 12.0027 22.532 12.5801Z" fill="#91C959"/>
                        <path d="M28.9813 6.09184L25.401 9.69497C24.8272 10.2724 23.9091 10.2724 23.3583 9.69497L19.778 6.09184C19.2042 5.51441 19.2042 4.59053 19.778 4.0362L23.3583 0.433069C23.9321 -0.144356 24.8501 -0.144356 25.401 0.433069L28.9813 4.0362C29.5551 4.59053 29.5551 5.51441 28.9813 6.09184Z" fill="#91C959"/>
                    </svg>
            </div>
            <div id="burgerOpen" class="cursor-pointer hover:text-dpgrey-50 text-white">
                <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 1H6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 6H6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 11H6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 3L1 6L3 9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </div>
        </div>
        <div class="flex flex-col w-full" id="mainLinks">
            <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-x-1 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center px-4 py-2 text-sm text-white transition-all w-full">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 shrink-0 w-6">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                        <path d="M9 15.1543V20.0629C9 21.091 9 21.6055 9.20436 21.9981C9.38413 22.3435 9.67076 22.6241 10.0236 22.8001C10.4243 23 10.9491 23 11.9971 23H21.0029C22.0509 23 22.575 23 22.9757 22.8001C23.3285 22.6241 23.6161 22.3435 23.7958 21.9981C24 21.6058 24 21.0922 24 20.0661V15.1543C24 14.6638 23.9996 14.4185 23.9387 14.1902C23.8847 13.988 23.7961 13.7966 23.6762 13.6237C23.5408 13.4285 23.3527 13.2667 22.9757 12.9437L18.4757 9.08868C17.7758 8.48906 17.4258 8.1894 17.0319 8.07536C16.6849 7.97488 16.3149 7.97488 15.9679 8.07536C15.5743 8.18931 15.2249 8.48869 14.526 9.08741L10.0245 12.9437C9.64748 13.2667 9.45941 13.4285 9.3241 13.6237C9.20418 13.7966 9.11489 13.988 9.06091 14.1902C9 14.4185 9 14.6638 9 15.1543Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                    </svg><span class="text-sm text-white">Home</span>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-x-1 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center px-4 py-2 text-sm text-white transition-all w-full">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 shrink-0 w-6">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                        <path d="M24 22.4999C24 21.0486 22.6087 19.8139 20.6667 19.3563M19 22.5C19 20.6591 16.7614 19.1667 14 19.1667C11.2386 19.1667 9 20.6591 9 22.5M19 16.6667C20.8409 16.6667 22.3333 15.1743 22.3333 13.3333C22.3333 11.4924 20.8409 10 19 10M14 16.6667C12.1591 16.6667 10.6667 15.1743 10.6667 13.3333C10.6667 11.4924 12.1591 10 14 10C15.8409 10 17.3333 11.4924 17.3333 13.3333C17.3333 15.1743 15.8409 16.6667 14 16.6667Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                    </svg>
                <span class="text-sm text-white">Team</span>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-x-1 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center px-4 py-2 text-sm text-white transition-all w-full">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 shrink-0 w-6">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                        <path d="M16.5 13.1667V17.3333H20.6667M24.003 11.1426L21.4495 9M11.5535 9L9 11.1426M16.5 24C12.8181 24 9.83333 21.0152 9.83333 17.3333C9.83333 13.6514 12.8181 10.6667 16.5 10.6667C20.1819 10.6667 23.1667 13.6514 23.1667 17.3333C23.1667 21.0152 20.1819 24 16.5 24Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                    </svg>
                <span class="text-sm text-white">Attendance</span>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-x-1 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center px-4 py-2 text-sm text-white transition-all w-full">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 shrink-0 w-6">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                        <path d="M23.5 16.5L16.75 21L10 16.5M23.5 19.5L16.75 24L10 19.5M23.5 13.5L16.75 18L10 13.5L16.75 9L23.5 13.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                    </svg>
                <span class="text-sm text-white">Projects</span>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-x-1 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center px-4 py-2 text-sm text-white transition-all w-full">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 shrink-0 w-6">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                        <path d="M9 14V22.6667C9 23.1334 9 23.3666 9.09083 23.5448C9.17072 23.7016 9.29811 23.8294 9.45492 23.9093C9.633 24 9.86625 24 10.332 24H19.0001M20.6667 13.1667L17.3333 16.5L15.6667 14.8333M12.3333 18.0002V11.6668C12.3333 10.7334 12.3333 10.2664 12.515 9.90983C12.6748 9.59623 12.9296 9.34144 13.2432 9.18166C13.5997 9 14.0667 9 15.0002 9H21.3335C22.2669 9 22.7334 9 23.0899 9.18166C23.4035 9.34144 23.6587 9.59623 23.8185 9.90983C24.0002 10.2663 24.0002 10.7331 24.0002 11.6665L24.0002 17.9998C24.0002 18.9333 24.0002 19.4 23.8185 19.7565C23.6587 20.0701 23.4035 20.3254 23.0899 20.4852C22.7337 20.6667 22.2679 20.6667 21.3363 20.6667H14.9974C14.0658 20.6667 13.5993 20.6667 13.2432 20.4852C12.9296 20.3254 12.6748 20.0702 12.515 19.7566C12.3333 19.4001 12.3333 18.9336 12.3333 18.0002Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                    </svg>
                <span class="text-sm text-white">My Tasks</span>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-x-1 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center px-4 py-2 text-sm text-white transition-all w-full">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 shrink-0 w-6">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                        <path d="M19.3127 20.4444V21.3333C19.3127 22.8061 18.0535 24 16.5001 24C14.9468 24 13.6876 22.8061 13.6876 21.3333V20.4444M19.3127 20.4444H13.6876M19.3127 20.4444H22.6788C23.0375 20.4444 23.2176 20.4444 23.3628 20.398C23.6402 20.3093 23.8573 20.1028 23.9508 19.8398C24 19.7016 24 19.5302 24 19.1875C24 19.0375 23.9998 18.9626 23.9874 18.8911C23.9641 18.7559 23.9088 18.6279 23.8245 18.5166C23.78 18.4579 23.7235 18.4042 23.612 18.2985L23.2468 17.9523C23.129 17.8405 23.0627 17.689 23.0627 17.531V14.2222C23.0627 10.7858 20.1246 7.99999 16.5001 8C12.8757 8.00001 9.93752 10.7858 9.93752 14.2222V17.5311C9.93752 17.689 9.87118 17.8405 9.75336 17.9523L9.38819 18.2985C9.27634 18.4045 9.22036 18.4578 9.17578 18.5167C9.09155 18.6279 9.03576 18.7559 9.01238 18.8911C9 18.9626 9 19.0375 9 19.1875C9 19.5302 9 19.7015 9.04917 19.8398C9.14274 20.1028 9.36075 20.3093 9.63813 20.398C9.78335 20.4444 9.96284 20.4444 10.3215 20.4444H13.6876" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                    </svg>
                <span class="text-sm text-white">Notifications</span>
            </div>
        </div>
        <div class="flex flex-col w-full z-20" id="favoriteProjects">
            <div class="items-start pb-1 pt-3 px-4" id="favoriteProjects">
                <span class="text-dpdarkblue-200 text-gray-500 text-xs uppercase">Favorite Projects</span>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#f15f5f] h-4 mt-0.5 rounded-full shrink-0 w-4"></div>
                    <p>Logo Design</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#70dfe7] h-4 mt-0.5 rounded-full shrink-0 w-4" style="background-color: #70dfe7;"></div>
                    <p>Project Tone</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#7ceebe] h-4 mt-0.5 rounded-full shrink-0 w-4" style="background-color: #7ceebe;"></div>
                    <p>Mala Company</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#f15f5f] h-4 mt-0.5 rounded-full shrink-0 w-4" style="background-color: #e3cf84;"></div>
                    <p>Somsads das sd e Client tu ama nue</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#8c42de] h-4 mt-0.5 rounded-full shrink-0 w-4"></div>
                    <p>All In dlop and nope project</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#f15f5f] h-4 mt-0.5 rounded-full shrink-0 w-4"></div>
                    <p>Logo Design</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#70dfe7] h-4 mt-0.5 rounded-full shrink-0 w-4" style="background-color: #70dfe7;"></div>
                    <p>Project Tone</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#7ceebe] h-4 mt-0.5 rounded-full shrink-0 w-4" style="background-color: #7ceebe;"></div>
                    <p>Mala Company</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#f15f5f] h-4 mt-0.5 rounded-full shrink-0 w-4" style="background-color: #e3cf84;"></div>
                    <p>Somsads das sd e Client tu ama nue</p>
                </div>
            </div>
            <div class="cursor-pointer duration-200 flex gap-2 hover:bg-dpdarkblue-400 hover:ml-1 items-start justify-between px-4 py-2 text-left text-sm text-white transition-all translate-y-2" id="favorite">
                <div class="flex gap-2">
                    <div class="bg-[#8c42de] h-4 mt-0.5 rounded-full shrink-0 w-4"></div>
                    <p>All In dlop and nope project</p>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
#sidebar {
    height: 100vh;
    overflow-y: auto;
}
</style>