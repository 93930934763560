import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_ACCOUNT, SET_ACCESS_TOKEN, SET_REFRESH_TOKEN, SET_ERRORMSG } from '../mutation_types'
import {
    LOGIN,
    LOGIN_OTP_VERIFY,
    SIGNUP,
    VERIFY_OTP,
    CLEAR_ACCOUNT_ISLOADING,
    RESEND_OTP,
    LOGOUT,
    REQUEST_PASSWORD_RESET,
    VERIFY_PASSWORD_RESET_OTP,
    RESET_PASSWORD
} from '../action_types'
import { StateObject } from '@/dtos/state'
import Auth from '@/services/auth'
import { LoginCredentals, RegistrationCredentals, ResetPassword, VerifyOTP, VerifyPasswordResetOTP } from '@/dtos/auth.dto'
import { Module } from 'vuex'

const account: Module<any, {}> = {
    state: {
        account: {
            data: null,
            status: null,
            isLoading: false
        },
        accessToken: null,
        refreshToken: null
    },
    getters: {
        account: (state: { account: any }) => state.account,
        accessToken: (state: { accessToken: any }) => state.accessToken,
        refreshToken: (state: { refreshToken: any }) => state.refreshToken
    },
    mutations: {
        [SET_ACCOUNT]: (state: { account: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.account.data = payload.data : null, state.account.status = payload.status, state.account.isLoading = payload.isLoading },
        [SET_ACCESS_TOKEN]: (state: { accessToken: any }, payload: any) => { state.accessToken = payload },
        [SET_REFRESH_TOKEN]: (state: { refreshToken: any }, payload: any) => { state.refreshToken = payload }
    },
    actions: {
        [LOGIN]: async({ commit }: any, payload: any) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.login(payload as LoginCredentals)
          
            if(result) {
                if (result.data.success) {
                    console.log(result.data)
                    commit(SET_ACCESS_TOKEN, result.data.data.accessToken)
                    commit(SET_REFRESH_TOKEN, result.data.data.refreshToken)
                    commit(SET_ACCOUNT, { data: result.data.data.lastLogin, isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [SIGNUP]: async({ commit }: any, payload: RegistrationCredentals) => {

            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.signup(payload.data)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_ACCOUNT, { isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [VERIFY_OTP]: async({ commit }: any, payload: any) => {

            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.verifyOTP(payload as VerifyOTP)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_ACCESS_TOKEN, result.data.data.accessToken)
                    commit(SET_REFRESH_TOKEN, result.data.data.refreshToken)
                    commit(SET_ACCOUNT, { data: result.data.data._doc, isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [RESEND_OTP]: async({ commit }: any, payload: string) => {

            commit(SET_ACCOUNT, { isLoading: true })

            let result: AxiosResponse = await Auth.resendOTP(payload)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_ACCOUNT, { isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [REQUEST_PASSWORD_RESET]: async({ commit }: any, payload: string) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.requestPasswordReset(payload)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_ACCOUNT, { isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [VERIFY_PASSWORD_RESET_OTP]: async({ commit }: any, payload: any) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.verifyResetPasswordOTP(payload as VerifyPasswordResetOTP)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_ACCOUNT, { isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [RESET_PASSWORD]: async({ commit }: any, payload: any) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.resetPassword(payload as ResetPassword)
          
            if(result) {
                if (result.data.success) {
                    commit(SET_ACCOUNT, { isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [LOGOUT]: async({ commit }) => {
            commit(SET_ACCOUNT, { isLoading: true })

            let result: AxiosResponse = await Auth.logout()
          
            if(result) {
                if (result.data.success) {
                    commit(SET_ACCESS_TOKEN, null)
                    commit(SET_REFRESH_TOKEN, null)
                    commit(SET_ACCOUNT, { data: null, isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.message)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [CLEAR_ACCOUNT_ISLOADING]: ({ commit }) => {
            commit(SET_ACCOUNT, { isLoading: false })
        }
    }
}

export default account