<template>
  <div>
    <div class="flex h-full overflow-hidden w-full">
      <Sidenav/>
      <div class="bg-dpgrey-50 bg-opacity-20 dark:bg-dpdarkblue-900 flex flex-1 flex-col overflow-auto" id="main">
          <div class="bg-white dark:bg-dpdarkblue-700 flex items-center justify-between min-h-[60px] px-5 sticky top-0 z-10" id="header">
              <div class="flex gap-2 items-center" id="left">
                  <div id="burgerClosed" class="cursor-pointer dark:hover:text-dpgrey-50 dark:text-white hover:text-dpgreen-400 text-dpgreen-600 hidden">
                      <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 11L17 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M1 6L17 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M1 0.999999L17 1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M20 9L22 6L20 3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </div>
                  <div class="flex gap-2 items-center" id="currentPage">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="24" height="24" rx="5" fill="#91C959"/>
                          <path d="M16.7363 10.9649C17.8373 10.8102 18.685 9.86628 18.6873 8.72267C18.6873 7.59563 17.8657 6.66117 16.7884 6.48438" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M18.1895 13.6133C19.2557 13.7727 20 14.146 20 14.9155C20 15.4451 19.6496 15.7892 19.0829 16.0055" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 13.9375C9.46335 13.9375 7.29688 14.3219 7.29688 15.8569C7.29688 17.3912 9.44993 17.7866 12 17.7866C14.5366 17.7866 16.7023 17.4062 16.7023 15.8704C16.7023 14.3345 14.55 13.9375 12 13.9375Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0005 11.747C13.665 11.747 15.0146 10.3982 15.0146 8.73287C15.0146 7.06836 13.665 5.71875 12.0005 5.71875C10.336 5.71875 8.98635 7.06836 8.98635 8.73287C8.98004 10.3919 10.3194 11.7415 11.9784 11.747H12.0005Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7.26351 10.9649C6.16173 10.8102 5.31487 9.86628 5.3125 8.72267C5.3125 7.59563 6.1341 6.66117 7.21142 6.48438" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M5.81052 13.6133C4.74426 13.7727 4 14.146 4 14.9155C4 15.4451 4.35042 15.7892 4.9171 16.0055" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span class="dark:text-dpgrey-50 font-bold text-sm md:text-lg">Team</span>
                  </div>
              </div>
              <div id="center" class="flex flex-1 justify-center px-4 md:justify-end">
                  <div id="timeStamp" class="relative">
                      <button id="startButton" class="bg-dpgreen-500 cursor-pointer dropdownToggle flex gap-2 hover:bg-dpgreen-400 items-center px-3 py-1.5 rounded-md text-sm text-white w-28">
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" id="iconClock">
                              <g clip-path="url(#clip0_860_8289)">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13 7C13 10.3139 10.3139 13 7 13C3.68605 13 1 10.3139 1 7C1 3.68605 3.68605 1 7 1C10.3139 1 13 3.68605 13 7Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M9.22592 8.90847L6.78052 7.44966V4.30566" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                                  <clipPath id="clip0_860_8289">
                                      <rect width="14" height="14" fill="white"/>
                                  </clipPath>
                              </defs>
                          </svg><span id="startText" class="flex-1 font-semibold">Start</span><span id="counter" class="flex-1 font-semibold hidden">00:00:00</span>
                      </button>
                      <div class="absolute bg-white dark:bg-dpdarkblue-600 dark:divide-dpdarkblue-400 dark:text-dpgrey-100 divide-dpgrey-50 divide-opacity-50 divide-y dp-shadow dropdownEffectCloseClick hidden right-0 rounded-md text-dpgrey-700 text-sm w-28 z-20" id="clockDropdown">
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center justify-center px-4 py-3 rounded-t-md transform transition-colors" id="checkIn">
                              <p>Check-in</p>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center justify-center px-4 py-3 transform transition-colors" id="pause">
                              <p>Pause</p>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center justify-center px-4 py-3 rounded-b-md transform transition-colors" id="checkOut">
                              <p>Check-out</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div id="right" class="flex gap-2 items-center md:gap-4">
                  <div class="hidden mt-1 relative md:block" id="add">
                      <button class="dark:hover:bg-dpdarkblue-400 dropdownToggle duration-200 hover:bg-dpgrey-50 hover:bg-opacity-25 p-1 relative rounded-lg transition-all">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5" id="iconAdd">
                              <circle cx="10" cy="10" r="9.5" fill="currentColor" stroke="#515151" class="dark:text-dpdarkblue-400 text-white"/>
                              <path d="M12.6881 9.23628H10.9601V7.50928C10.9601 7.09828 10.6271 6.76428 10.2161 6.76428C9.80512 6.76428 9.47112 7.09828 9.47112 7.50928V9.23628H7.74412C7.33312 9.23628 7.00012 9.57028 7.00012 9.98128C7.00012 10.3923 7.33312 10.7263 7.74412 10.7263H9.47112V12.4523C9.47112 12.8633 9.80512 13.1973 10.2161 13.1973C10.6271 13.1973 10.9601 12.8633 10.9601 12.4523V10.7263H12.6881C13.0991 10.7263 13.4331 10.3923 13.4331 9.98128C13.4331 9.57028 13.0991 9.23628 12.6881 9.23628Z" fill="currentColor" class="text-dpgreen-500"/>
                          </svg>
                      </button>
                      <div class="absolute bg-white dark:bg-dpdarkblue-600 dark:divide-dpdarkblue-400 dark:text-dpgrey-100 divide-dpgrey-50 divide-opacity-50 divide-y dp-shadow dropdownEffectCloseClick hidden right-0 rounded-md text-dpgrey-700 text-sm w-48 z-20" id="addDropdown">
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center px-4 py-3 transform transition-colors" id="addTask">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-4">
                                  <circle cx="10" cy="10" r="9.5" fill="currentColor" stroke="#515151" class="dark:text-dpdarkblue-400 text-white"/>
                                  <path d="M12.6881 9.23628H10.9601V7.50928C10.9601 7.09828 10.6271 6.76428 10.2161 6.76428C9.80512 6.76428 9.47112 7.09828 9.47112 7.50928V9.23628H7.74412C7.33312 9.23628 7.00012 9.57028 7.00012 9.98128C7.00012 10.3923 7.33312 10.7263 7.74412 10.7263H9.47112V12.4523C9.47112 12.8633 9.80512 13.1973 10.2161 13.1973C10.6271 13.1973 10.9601 12.8633 10.9601 12.4523V10.7263H12.6881C13.0991 10.7263 13.4331 10.3923 13.4331 9.98128C13.4331 9.57028 13.0991 9.23628 12.6881 9.23628Z" fill="currentColor" class="text-dpgreen-500"/>
                              </svg>
                              <p>Add new Task</p>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center px-4 py-3 rounded-b-md transform transition-colors" id="addProject">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-4">
                                  <circle cx="10" cy="10" r="9.5" fill="currentColor" stroke="#515151" class="dark:text-dpdarkblue-400 text-white"/>
                                  <path d="M12.6881 9.23628H10.9601V7.50928C10.9601 7.09828 10.6271 6.76428 10.2161 6.76428C9.80512 6.76428 9.47112 7.09828 9.47112 7.50928V9.23628H7.74412C7.33312 9.23628 7.00012 9.57028 7.00012 9.98128C7.00012 10.3923 7.33312 10.7263 7.74412 10.7263H9.47112V12.4523C9.47112 12.8633 9.80512 13.1973 10.2161 13.1973C10.6271 13.1973 10.9601 12.8633 10.9601 12.4523V10.7263H12.6881C13.0991 10.7263 13.4331 10.3923 13.4331 9.98128C13.4331 9.57028 13.0991 9.23628 12.6881 9.23628Z" fill="currentColor" class="text-dpgreen-500"/>
                              </svg>
                              <p>Add new Project</p>
                          </div>
                      </div>
                  </div>
                  <div class="hidden mt-1 relative md:block" id="notification">
                      <button class="dark:hover:bg-dpdarkblue-400 dropdownToggle duration-200 hover:bg-dpgrey-50 hover:bg-opacity-25 p-1 relative rounded-lg transition-all">
                          <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5" id="iconNotifications">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 16.8476C15.1392 16.8476 17.7481 16.1242 18 13.2205C18 10.3188 16.1812 10.5054 16.1812 6.94511C16.1812 4.16414 13.5452 1 9.5 1C5.45477 1 2.81885 4.16414 2.81885 6.94511C2.81885 10.5054 1 10.3188 1 13.2205C1.25295 16.1352 3.86177 16.8476 9.5 16.8476Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="dark:text-dpgrey-300 text-dpgrey-500"/>
                              <path d="M11.8887 19.8572C10.5246 21.3719 8.39659 21.3899 7.01941 19.8572" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="dark:text-dpgrey-300 text-dpgrey-500"/>
                          </svg>
                      </button>
                      <div class="absolute bg-white dark:bg-dpdarkblue-600 dark:divide-dpdarkblue-400 dark:text-dpgrey-100 dark:text-sm divide-dpgrey-50 divide-opacity-50 divide-y dp-shadow dropdownEffectCloseClick hidden overflow-hidden right-0 rounded-md text-sm w-80 z-20" id="notificationDropdown">
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex flex-col gap-1 hover:bg-dpgrey-50 hover:bg-opacity-20 hover:bg-opacity-25 items-start px-4 py-3 transform transition-colors" id="notification">
                              <p href="#">Sara Salah added a new comment.</p>
                              <div class="flex justify-between text-xs w-full">
                                  <p href="#">Magica School Project</p>
                                  <p href="#">12 sec ago</p>
                              </div>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex flex-col gap-1 hover:bg-dpgrey-50 hover:bg-opacity-20 hover:bg-opacity-25 items-start px-4 py-3 transform transition-colors" id="notification">
                              <p href="#">You have been added to a project.</p>
                              <div class="flex justify-between text-xs w-full">
                                  <p href="#">Magica School Project</p>
                                  <p href="#">3 mins ago</p>
                              </div>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex flex-col gap-1 hover:bg-dpgrey-50 hover:bg-opacity-20 hover:bg-opacity-25 items-start px-4 py-3 transform transition-colors" id="notification">
                              <p href="#">You have been added to a new team.</p>
                              <div class="flex justify-between text-xs w-full">
                                  <p href="#">Magica School Project</p>
                                  <p href="#">1 hour ago</p>
                              </div>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex flex-col gap-1 hover:bg-dpgrey-50 hover:bg-opacity-20 hover:bg-opacity-25 items-start px-4 py-3 transform transition-colors" id="notification">
                              <p href="#">Mark Boss replied to your comment.</p>
                              <div class="flex justify-between text-xs w-full">
                                  <p href="#">Magica School Project</p>
                                  <p href="#">5 hours ago</p>
                              </div>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex flex-col gap-1 hover:bg-dpgrey-50 hover:bg-opacity-20 hover:bg-opacity-25 items-start px-4 py-3 transform transition-colors" id="notification">
                              <p href="#">Your absence request has been approved.</p>
                              <div class="flex justify-between text-xs w-full">
                                  <p href="#">Absence</p>
                                  <p href="#">3 days ago</p>
                              </div>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex flex-col gap-1 hover:bg-dpgrey-50 hover:bg-opacity-20 hover:bg-opacity-25 items-start px-4 py-3 transform transition-colors" id="notification">
                              <p href="#">Your attendance change has been declined.</p>
                              <div class="flex justify-between text-xs w-full">
                                  <p href="#">Ateendance</p>
                                  <p href="#">01.02.2024</p>
                              </div>
                          </div>
                          <div class="bg-dpblue-500 block cursor-pointer hover:bg-dpblue-400 py-2 text-center text-sm text-white" id="allNotifications">
                              <p>See all notifications</p>
                          </div>
                      </div>
                  </div>
                  <div class="mt-1 relative" id="searchContainer">
                      <button class="dark:hover:bg-dpdarkblue-400 dropdownToggle duration-200 hover:bg-dpgrey-50 hover:bg-opacity-25 p-1 relative rounded-lg transition-all">
                          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5" id="iconSearch">
                              <circle cx="9.98856" cy="9.98856" r="8.98856" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="dark:text-dpgrey-300 text-dpgrey-500"/>
                              <path d="M16.2402 16.7072L19.7643 20.222" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="dark:text-dpgrey-300 text-dpgrey-500"/>
                          </svg>
                      </button>
                      <div class="absolute dark:text-dpgrey-100 dropdownEffect flex hidden items-center mt-2 right-0 w-64 z-20 lg:w-96" id="searchDropdown">
                          <!-- Search Icon -->
                          <div class="absolute left-3">
                              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-4">
                                  <circle cx="9.98856" cy="9.98856" r="8.98856" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="dark:text-dpgrey-300 text-dpblue-500"/>
                                  <path d="M16.2402 16.7072L19.7643 20.222" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="dark:text-dpgrey-300 text-dpblue-500"/>
                              </svg>
                          </div>
                          <!-- Input Field -->
                          <input type="text" id="searchInput" placeholder="Search for projects, tasks, members..." class="border-transparent dark:bg-dpdarkblue-600 dark:focus:bg-dpdarkblue-400 dp-shadow duration-200 focus:border-dpgreen-500 focus:outline-none focus:ring-0 focus:ring-blue-500 focus:ring-dpgreen-500 h-11 pl-10 pr-10 py-2 rounded-xl text-sm transition w-full" oninput="handleInput()">
                          <!-- Clear Button -->
                          <div id="clearButton" class="absolute cursor-pointer hidden right-3" onclick="clearSearch()">
                              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8.99998 8.99998L5.00001 5.00001M5.00001 5.00001L1 1M5.00001 5.00001L9.00002 1M5.00001 5.00001L1 9.00002" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </div>
                      </div>
                      <!-- Results Dropdown -->
                      <div id="resultsDropdown" class="absolute bg-white dark:bg-dpdarkblue-600 dark:text-dpgrey-50 hidden mt-14 right-0 rounded-lg shadow-lg w-64 z-30 lg:w-96">
                          <ul id="resultsList" class="max-h-48 overflow-y-auto">
                              <li class="cursor-pointer dark:hover:bg-dpdarkblue-400 hover:bg-gray-100 px-4 py-2 text-sm truncate" onclick="selectResult(this)">Project Alpha Project Alpha Project Alpha Project Alpha</li>
                              <li class="cursor-pointer dark:hover:bg-dpdarkblue-400 hover:bg-gray-100 px-4 py-2 text-sm truncate" onclick="selectResult(this)">Project Beta</li>
                              <li class="cursor-pointer dark:hover:bg-dpdarkblue-400 hover:bg-gray-100 px-4 py-2 text-sm truncate" onclick="selectResult(this)">Task for Project One</li>
                              <li class="cursor-pointer dark:hover:bg-dpdarkblue-400 hover:bg-gray-100 px-4 py-2 text-sm truncate" onclick="selectResult(this)">Member of Project One</li>
                              <li class="cursor-pointer dark:hover:bg-dpdarkblue-400 hover:bg-gray-100 px-4 py-2 text-sm truncate" onclick="selectResult(this)">Another Project One Task</li>
                              <li class="cursor-pointer dark:hover:bg-dpdarkblue-400 hover:bg-gray-100 px-4 py-2 text-sm truncate" onclick="selectResult(this)">Something unrelated</li>
                              <li class="cursor-pointer dark:hover:bg-dpdarkblue-400 hover:bg-gray-100 px-4 py-2 text-sm truncate" onclick="selectResult(this)">Yet Another Project</li>
                          </ul>
                      </div>
                  </div>
                  <div class="dark:text-dpgrey-100 hidden mt-1 relative text-dpgrey-700 md:block" id="help">
                      <button class="dark:hover:bg-dpdarkblue-400 dropdownToggle duration-200 hover:bg-dpgrey-50 hover:bg-opacity-25 p-1 relative rounded-lg transition-all">
                          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5" id="iconHelp">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.25 1C15.358 1 19.5 5.141 19.5 10.25C19.5 15.358 15.358 19.5 10.25 19.5C5.141 19.5 1 15.358 1 10.25C1 5.141 5.141 1 10.25 1Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="dark:text-dpgrey-300 text-dpgrey-500"/>
                              <path d="M9.13435 11.3579C9.13435 11.0779 9.17168 10.8399 9.24635 10.6439C9.32102 10.4479 9.44702 10.2659 9.62435 10.0979C9.88569 9.84588 10.1237 9.62654 10.3384 9.43988C10.5624 9.25321 10.7537 9.08054 10.9124 8.92188C11.071 8.75388 11.1924 8.59988 11.2764 8.45988C11.3697 8.31054 11.4164 8.15654 11.4164 7.99788C11.4164 7.68988 11.3184 7.45188 11.1224 7.28388C10.9357 7.10654 10.6977 7.01788 10.4084 7.01788C9.96969 7.01788 9.63369 7.13921 9.40035 7.38188C9.17635 7.62454 9.05035 7.91854 9.02235 8.26388L6.86635 8.12388C6.96902 7.13454 7.33302 6.38321 7.95835 5.86988C8.58369 5.34721 9.38635 5.08588 10.3664 5.08588C10.7957 5.08588 11.197 5.14654 11.5704 5.26788C11.953 5.37988 12.289 5.55254 12.5784 5.78588C12.8677 6.01921 13.0964 6.30854 13.2644 6.65388C13.4324 6.99921 13.5164 7.39588 13.5164 7.84388C13.5164 8.11454 13.4884 8.36188 13.4324 8.58588C13.3764 8.80054 13.2784 9.01521 13.1384 9.22988C13.0077 9.44454 12.8304 9.66388 12.6064 9.88788C12.3917 10.1025 12.1164 10.3405 11.7804 10.6019C11.5657 10.7699 11.421 10.9332 11.3464 11.0919C11.2717 11.2505 11.2344 11.4092 11.2344 11.5679V11.9739H9.13435V11.3579ZM8.89635 14.0459C8.89635 13.8685 8.92902 13.7005 8.99435 13.5419C9.05969 13.3832 9.14835 13.2479 9.26035 13.1359C9.38168 13.0145 9.52169 12.9212 9.68035 12.8559C9.83902 12.7905 10.007 12.7579 10.1844 12.7579C10.3617 12.7579 10.5297 12.7905 10.6884 12.8559C10.847 12.9212 10.9824 13.0145 11.0944 13.1359C11.2157 13.2479 11.309 13.3832 11.3744 13.5419C11.4397 13.7005 11.4724 13.8685 11.4724 14.0459C11.4724 14.2232 11.4397 14.3912 11.3744 14.5499C11.309 14.7085 11.2157 14.8485 11.0944 14.9699C10.9824 15.0819 10.847 15.1705 10.6884 15.2359C10.5297 15.3012 10.3617 15.3339 10.1844 15.3339C10.007 15.3339 9.83902 15.3012 9.68035 15.2359C9.52169 15.1705 9.38168 15.0819 9.26035 14.9699C9.14835 14.8485 9.05969 14.7085 8.99435 14.5499C8.92902 14.3912 8.89635 14.2232 8.89635 14.0459Z" fill="currentColor" class="dark:text-dpgrey-300 text-dpgrey-500"/>
                          </svg>
                      </button>
                      <div class="absolute bg-white dark:bg-dpdarkblue-600 dark:divide-dpdarkblue-400 divide-dpgrey-50 divide-opacity-50 divide-y dp-shadow dropdownEffectCloseClick hidden right-0 rounded-md text-sm w-32 z-20" id="helpDropdown">
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center px-4 py-3 rounded-t-md transform transition-colors" id="faq">
                              <p>FAQ</p>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center px-4 py-3 transform transition-colors" id="tutorials">
                              <p>Tutorials</p>
                          </div>
                          <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center px-4 py-3 rounded-b-md transform transition-colors" id="support">
                              <p>Support</p>
                          </div>
                      </div>
                  </div>
                  <div class="mt-1 relative" id="profile">
                      <div class="bg-dpred-500 cursor-pointer dropdownToggle flex h-9 hover:bg-dpred-600 items-center justify-center leading-5 min-h-9 min-w-9 relative rounded-full shrink-0 text-sm text-white uppercase w-9">
                          <span>JC</span>
                          <div class="absolute bg-dporange-500 border border-white bottom-0 h-3 right-0 rounded-full w-3" id="statusPause"></div>
                          <div class="absolute bg-dpgreen-500 border border-white bottom-0 h-3 right-0 rounded-full w-3" id="statusOnline"></div>
                      </div>
                      <div class="absolute bg-white dark:bg-dpdarkblue-600 dark:divide-dpdarkblue-400 dark:text-dpgrey-100 divide-dpgrey-50 divide-opacity-50 divide-y dp-shadow dropdownEffectCloseClick hidden mt-2 overflow-hidden right-0 rounded-md text-dpgrey-700 text-sm w-56 z-20" id="profileDropdown">
                          <div id="workspaces">
                              <div class="duration-200 flex gap-1 items-center justify-between pb-1 pl-4 pr-3 pt-3 transform transition-colors" id="workspaceTitle">
                                  <p class="dark:text-dpdarkblue-50 text-dpgrey-200 text-xs">Workspace</p>
                              </div>
                              <div class="bg-dpgrey-50 bg-opacity-40 dark:bg-dpdarkblue-400 dark:hover:bg-dpdarkblue-400 duration-200 flex font-bold gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-start justify-between pl-4 pr-3 py-3 transform transition-colors" id="workspace">
                                  <div id="left" class="flex-1">
                                      <p>Personal</p>
                                  </div>
                                  <div class="flex justify-end w-12" id="right">
                                      <div class="cursor-pointer dark:hover:bg-dpdarkblue-300 hover:bg-dpgrey-50 hover:bg-opacity-25 p-1 rounded-lg" id="workspaceSettings">
                                          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-4">
                                              <path d="M8.94151 15.5247L13.1676 13.0848C13.6187 12.8243 13.8438 12.6941 14.0079 12.5119C14.153 12.3507 14.2628 12.1608 14.3298 11.9545C14.4054 11.7218 14.4054 11.4619 14.4054 10.9434V6.05643C14.4054 5.53795 14.4054 5.27802 14.3298 5.0454C14.2628 4.83909 14.153 4.64905 14.0079 4.48784C13.8445 4.30643 13.6199 4.17675 13.1727 3.91853L8.94075 1.47523C8.48962 1.21477 8.2645 1.0848 8.02469 1.03383C7.8125 0.988725 7.59313 0.988725 7.38094 1.03383C7.14113 1.0848 6.91526 1.21477 6.46413 1.47523L2.23726 3.91561C1.78665 4.17577 1.56152 4.30575 1.39757 4.48784C1.25241 4.64905 1.1428 4.83909 1.07576 5.0454C1 5.27857 1 5.53917 1 6.0601V10.9399C1 11.4609 1 11.7213 1.07576 11.9545C1.1428 12.1608 1.25241 12.3507 1.39757 12.5119C1.56162 12.6941 1.78691 12.8243 2.23805 13.0848L6.46413 15.5247C6.91525 15.7852 7.14114 15.9152 7.38094 15.9662C7.59313 16.0113 7.8125 16.0113 8.02469 15.9662C8.2645 15.9152 8.49038 15.7852 8.94151 15.5247Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-dpgrey-400"/>
                                              <path d="M5.38079 8.49992C5.38079 9.78228 6.42035 10.8218 7.7027 10.8218C8.98506 10.8218 10.0246 9.78228 10.0246 8.49992C10.0246 7.21756 8.98506 6.17801 7.7027 6.17801C6.42035 6.17801 5.38079 7.21756 5.38079 8.49992Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-dpgrey-400"/>
                                          </svg>
                                      </div>
                                      <div class="p-1" id="currentWorkspaceCheck">
                                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-4">
                                              <path d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z" fill="#91C959"/>
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.38866 10.3846C6.20969 10.3846 6.02884 10.3172 5.89204 10.1814L3.66747 7.98942C3.39291 7.71878 3.39291 7.28094 3.66747 7.01029C3.94202 6.73965 4.38619 6.73965 4.66074 7.01029L6.38866 8.71175L10.3393 4.81834C10.6138 4.54769 11.058 4.54769 11.3325 4.81834C11.6071 5.08899 11.6071 5.52683 11.3325 5.79747L6.8853 10.1814C6.7485 10.3172 6.56858 10.3846 6.38866 10.3846Z" fill="white"/>
                                          </svg>
                                      </div>
                                  </div>
                              </div>
                              <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-start justify-between pl-4 pr-3 py-3 transform transition-colors" id="workspace">
                                  <div id="left" class="flex-1">
                                      <p>Company One</p>
                                  </div>
                                  <div class="flex justify-end w-12" id="right">
</div>
                              </div>
                              <div class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-start justify-between pl-4 pr-3 py-3 transform transition-colors" id="workspace">
                                  <div id="left" class="flex-1">
                                      <p>Company Two and More</p>
                                  </div>
                                  <div class="flex justify-end w-12" id="right">
</div>
                              </div>                                     
                          </div>
                          <div class="plan">
                              <div class="duration-200 flex gap-1 items-center justify-between pl-4 pr-3 pt-3 transform transition-colors">
                                  <p class="dark:text-dpdarkblue-50 text-dpgrey-200 text-xs">Current Plan</p>
                              </div>
                              <a href="#" class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center justify-between pl-4 pr-3 py-3 transform transition-colors"> <p>Free Plan</p><button class="bg-dpblue-500 flex flex-row focus:outline-none hover:bg-dpblue-400 items-center px-2 py-1 rounded-lg text-white text-xs">Upgrade</button> </a>
                          </div><a href="#" class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center justify-between pl-4 pr-3 py-3 transform transition-colors"> <p>Profile</p> </a><a href="#" class="cursor-pointer dark:hover:bg-dpdarkblue-400 duration-200 flex gap-1 hover:bg-dpgrey-50 hover:bg-opacity-25 items-center justify-between pl-4 pr-3 py-3 transform transition-colors"> <p>Settings</p> </a><a href="#" class="bg-dpgold-500 block hover:bg-dpgold-400 pl-4 pr-3 py-3 text-left text-sm text-white">Log Out</a>
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex flex-1 flex-col p-5" id="content" stroke="#515151">
              <!-- Add more content as needed -->
          </div>
          <footer class="bg-dpdarkblue-500 bottom-0 dark:bg-dpdarkblue-700 dark:bg-tdgreodark-900 fixed h-16 md:hidden w-full z-50">
              <div class="flex h-full items-center justify-between mx-auto px-5">
                  <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-y-1 hover:bg-dpdarkblue-400 hover:translate-x-1 items-center justify-center px-4 py-2 rounded-lg text-sm text-white transition-all w-full">
                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-9 shrink-0">
                          <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                          <path d="M23.5 16.5L16.75 21L10 16.5M23.5 19.5L16.75 24L10 19.5M23.5 13.5L16.75 18L10 13.5L16.75 9L23.5 13.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                      </svg>
                  </div>
                  <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-y-1 hover:bg-dpdarkblue-400 hover:translate-x-1 items-center justify-center px-4 py-2 rounded-lg text-sm text-white transition-all w-full">
                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-9 shrink-0">
                          <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                          <path d="M9 14V22.6667C9 23.1334 9 23.3666 9.09083 23.5448C9.17072 23.7016 9.29811 23.8294 9.45492 23.9093C9.633 24 9.86625 24 10.332 24H19.0001M20.6667 13.1667L17.3333 16.5L15.6667 14.8333M12.3333 18.0002V11.6668C12.3333 10.7334 12.3333 10.2664 12.515 9.90983C12.6748 9.59623 12.9296 9.34144 13.2432 9.18166C13.5997 9 14.0667 9 15.0002 9H21.3335C22.2669 9 22.7334 9 23.0899 9.18166C23.4035 9.34144 23.6587 9.59623 23.8185 9.90983C24.0002 10.2663 24.0002 10.7331 24.0002 11.6665L24.0002 17.9998C24.0002 18.9333 24.0002 19.4 23.8185 19.7565C23.6587 20.0701 23.4035 20.3254 23.0899 20.4852C22.7337 20.6667 22.2679 20.6667 21.3363 20.6667H14.9974C14.0658 20.6667 13.5993 20.6667 13.2432 20.4852C12.9296 20.3254 12.6748 20.0702 12.515 19.7566C12.3333 19.4001 12.3333 18.9336 12.3333 18.0002Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                      </svg>
                  </div>
                  <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center justify-center px-4 py-2 rounded-lg text-sm text-white transition-all w-full">
                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-9 shrink-0">
                          <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                          <path d="M9 15.1543V20.0629C9 21.091 9 21.6055 9.20436 21.9981C9.38413 22.3435 9.67076 22.6241 10.0236 22.8001C10.4243 23 10.9491 23 11.9971 23H21.0029C22.0509 23 22.575 23 22.9757 22.8001C23.3285 22.6241 23.6161 22.3435 23.7958 21.9981C24 21.6058 24 21.0922 24 20.0661V15.1543C24 14.6638 23.9996 14.4185 23.9387 14.1902C23.8847 13.988 23.7961 13.7966 23.6762 13.6237C23.5408 13.4285 23.3527 13.2667 22.9757 12.9437L18.4757 9.08868C17.7758 8.48906 17.4258 8.1894 17.0319 8.07536C16.6849 7.97488 16.3149 7.97488 15.9679 8.07536C15.5743 8.18931 15.2249 8.48869 14.526 9.08741L10.0245 12.9437C9.64748 13.2667 9.45941 13.4285 9.3241 13.6237C9.20418 13.7966 9.11489 13.988 9.06091 14.1902C9 14.4185 9 14.6638 9 15.1543Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                      </svg>
                  </div>
                  <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-x-1 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center justify-center px-4 py-2 rounded-lg text-sm text-white transition-all w-full">
                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-9 shrink-0">
                          <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                          <path d="M16.5 13.1667V17.3333H20.6667M24.003 11.1426L21.4495 9M11.5535 9L9 11.1426M16.5 24C12.8181 24 9.83333 21.0152 9.83333 17.3333C9.83333 13.6514 12.8181 10.6667 16.5 10.6667C20.1819 10.6667 23.1667 13.6514 23.1667 17.3333C23.1667 21.0152 20.1819 24 16.5 24Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                      </svg>
                  </div>
                  <div class="cursor-pointer duration-200 flex gap-2 h-11 hover:-translate-x-1 hover:-translate-y-1 hover:bg-dpdarkblue-400 items-center justify-center px-4 py-2 rounded-lg text-sm text-white transition-all w-full">
                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-9 shrink-0">
                          <circle cx="16.5" cy="16.5" r="16.5" fill="currentColor" class="text-dpdarkblue-300"/>
                          <path d="M19.3127 20.4444V21.3333C19.3127 22.8061 18.0535 24 16.5001 24C14.9468 24 13.6876 22.8061 13.6876 21.3333V20.4444M19.3127 20.4444H13.6876M19.3127 20.4444H22.6788C23.0375 20.4444 23.2176 20.4444 23.3628 20.398C23.6402 20.3093 23.8573 20.1028 23.9508 19.8398C24 19.7016 24 19.5302 24 19.1875C24 19.0375 23.9998 18.9626 23.9874 18.8911C23.9641 18.7559 23.9088 18.6279 23.8245 18.5166C23.78 18.4579 23.7235 18.4042 23.612 18.2985L23.2468 17.9523C23.129 17.8405 23.0627 17.689 23.0627 17.531V14.2222C23.0627 10.7858 20.1246 7.99999 16.5001 8C12.8757 8.00001 9.93752 10.7858 9.93752 14.2222V17.5311C9.93752 17.689 9.87118 17.8405 9.75336 17.9523L9.38819 18.2985C9.27634 18.4045 9.22036 18.4578 9.17578 18.5167C9.09155 18.6279 9.03576 18.7559 9.01238 18.8911C9 18.9626 9 19.0375 9 19.1875C9 19.5302 9 19.7015 9.04917 19.8398C9.14274 20.1028 9.36075 20.3093 9.63813 20.398C9.78335 20.4444 9.96284 20.4444 10.3215 20.4444H13.6876" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="text-white"/>
                      </svg>
                  </div>
              </div>
          </footer>
      </div>
  </div>
  </div>
</template>
<script>
import Sidenav from '@/components/Sidenav.vue'
import { LOGOUT } from '@/store/action_types'

export default {
  components: {
    Sidenav
  },
  computed: {
    errorMessage() {
      return this.$store.getters.errorMsg
    },
    isLoading() {
      return this.$store.getters.account.isLoading
    },
  },
  methods: {
      logout() {
          this.$store.dispatch(LOGOUT).then((res) => {
              if(res) {
                this.$router.go()
              }
          })
      }
  }
}
</script>