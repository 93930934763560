export const LOGIN_URL = '/user/login'
export const LOGIN_VERIFY_OTP_URL = ''

export const SIGNUP_URL = '/user/register'
export const SIGNUP_VERIFY_OTP_URL = '/user/email/verifycode'

export const RESEND_OTP_URL = '/user/email/sendcode'

export const REQUEST_PASSWORD_RESET_URL = '/user/password/resetByEmail/sendcode'
export const VERIFY_RESET_PASSWORD_OTP_URL = '/user/password/resetByEmail/verifycode'
export const RESET_PASSWORD_URL = '/user/password/resetByEmail/change'

export const GET_TEAM_GROUP_URL = '/team/group/byTeam'
export const CREATE_TEAM_GROUP_URL = '/team/group/create'
export const EDIT_TEAM_GROUP_URL = '/user/password/resetByEmail/change'
export const DELETE_TEAM_GROUP_URL = '/team/group/delete'

export const GET_TEAM_ROLE_URL = '/team/role/byTeam'
export const CREATE_TEAM_ROLE_URL = '/team/role/create'
export const EDIT_TEAM_ROLE_URL = '/user/password/resetByEmail/change'
export const DELETE_TEAM_ROLE_URL = '/team/role/delete'

export const GET_TEAM_MEMBER_URL = '/team/member'
export const CREATE_TEAM_MEMBER_URL = '/team/member/add'
export const EDIT_TEAM_MEMBER_URL = '/user/password/resetByEmail/change'
export const DELETE_TEAM_MEMBER_URL = '/team/member/delete'

export const LOGOUT_URL = '/user/logout'