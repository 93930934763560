import baseAPI from '@/api/base';
import { EditTeamRoleDto, NewTeamRoleDto } from '@/dtos/team/role.dto';
import { CREATE_TEAM_ROLE_URL, DELETE_TEAM_ROLE_URL, EDIT_TEAM_ROLE_URL, GET_TEAM_ROLE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Role {
    static getTeamRole = async(teamId: string | null) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(`${GET_TEAM_ROLE_URL}/${teamId}`, {
                    ...(teamId != null ? { params: { teamId } } : {})
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
    
    static createTeamRole = async(newTeamRoleDto: NewTeamRoleDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(CREATE_TEAM_ROLE_URL, {
                    ...newTeamRoleDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editTeamRole = async(editTeamRoleDto: EditTeamRoleDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(`${EDIT_TEAM_ROLE_URL}`, {
                    ...editTeamRoleDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static addMemberToRole = async(editTeamRoleDto: EditTeamRoleDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(`${EDIT_TEAM_ROLE_URL}`, {
                    ...editTeamRoleDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteTeamRole = async(roleId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_TEAM_ROLE_URL}/${roleId}`, {
                    data: { roleId }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Role