export const DASHBOARD_ROUTE = 'DASHBOARD_ROUTE'

// signup
export const SIGNUP_ROUTE = 'SIGNUP_ROUTE'
export const SIGNUP_OTP_ROUTE = 'SIGNUP_OTP_ROUTE'
export const SIGNUP_SUCCESS_ROUTE = 'SIGNUP_SUCCESS_ROUTE'

// login
export const LOGIN_ROUTE = 'LOGIN_ROUTE'
export const LOGIN_OTP_ROUTE = 'LOGIN_OTP_ROUTE'

// reset_password
export const REQUEST_RESET_PASSWORD_ROUTE = 'REQUEST_RESET_PASSWORD_ROUTE'
export const VERIFY_PASSWORD_RESET_OTP_ROUTE = 'VERIFY_PASSWORD_RESET_OTP_ROUTE'
export const RESET_PASSWORD_ROUTE = 'RESET_PASSWORD_ROUTE'
export const PASSWORD_RESET_SUCCESSFUL_ROUTE = 'PASSWORD_RESET_SUCCESSFUL_ROUTE'


// team
export const TEAM_GROUPS_ROUTE = 'TEAM_GROUPS_ROUTE'
export const TEAM_MEMBERS_ROUTE = 'TEAM_MEMBERS_ROUTE'
export const TEAM_ROLES_ROUTE = 'TEAM_ROLES_ROUTE'